import React, { forwardRef, type MouseEvent } from 'react';
import classNames from 'classnames';
import { useTracking } from 'react-tracking';
import { Flex } from '@user-interviews/ui-design-system';
import * as routes from 'lib/routes';
import { trackingEvents } from 'lib/analytics';

import { useAuthUserContext } from 'hooks/use_auth_user_context';

import accountSettingsNavigationItemStyles from '../../account_navigation_item.module.scss';
import styles from './account_menu_popover.module.scss';

const {
  NAVIGATION_ACCOUNT_CLICKED,
  NAVIGATION_SIGN_OUT_CLICKED,
  NAVIGATION_TEAMS_CLICKED,
} = trackingEvents;

export const AccountMenuPopover = forwardRef<HTMLDivElement>((_props, ref) => {
  const { trackEvent } = useTracking();
  const authUserContext = useAuthUserContext();

  if (!authUserContext?.account) return null;

  const { account, team } = authUserContext;

  function handleLinkClick({ currentTarget }: MouseEvent<HTMLAnchorElement>) {
    const trackingEvent = currentTarget.dataset.event;
    if (!trackingEvent) return;

    trackEvent({ event: trackingEvent });
  }

  return (
    <div
      className={accountSettingsNavigationItemStyles.accountMenuPopoverContainer}
      ref={ref}
    >
      <Flex
        alignItems="flex-start"
        className={classNames(
          styles.accountSettingsPopover,
        )}
        container
        flexDirection="column"
      >
        {team && (
          <a
            className={styles.link}
            data-event={NAVIGATION_TEAMS_CLICKED}
            href={routes.team_members_path()}
            onClick={handleLinkClick}
          >
            Team settings
          </a>
        )}
        <a
          className={styles.link}
          data-event={NAVIGATION_ACCOUNT_CLICKED}
          href={routes.edit_account_path(account.id)}
          onClick={handleLinkClick}
        >
          Account settings
        </a>
        <a
          className={styles.link}
          data-event={NAVIGATION_SIGN_OUT_CLICKED}
          data-method="delete"
          href="/accounts/signout"
          onClick={handleLinkClick}
        >
          Sign out
        </a>
      </Flex>
    </div>
  );
});

import React, {
  forwardRef,
  type ForwardedRef,
} from 'react';
import classNames from 'classnames';

import * as routes from 'lib/routes';
import { trackingEvents } from 'lib/analytics/constants';
import { useAuthUserContext } from 'hooks/use_auth_user_context';

import { NavigationItem } from '../navigation_item';
import { AccountNavigationItem } from './components/account_navigation_item';
import { useSidebarContext } from './hooks/use_sidebar_context';

import styles from './sidebar.module.scss';

const {
  NAVIGATION_PARTICIPANTS_CLICKED,
  NAVIGATION_PLANS_CLICKED,
  NAVIGATION_PROJECTS_CLICKED,
  NAVIGATION_SUPPORT_CLICKED,
} = trackingEvents;

type SidebarProps = {
  onHandlePageLoading?: () => void
}

export const Sidebar = forwardRef((
  { onHandlePageLoading }: SidebarProps,
  sidebarRef: ForwardedRef<HTMLDivElement>,
) => {
  const { isOpen, isTransitioning } = useSidebarContext();
  const authContext = useAuthUserContext();

  const isAccount = !!authContext.account;
  const isStaff = !!authContext.user;
  const rootPath = isAccount ?
    routes.account_dashboard_path() :
    '/staff';

  return (
    <div
      className={classNames(
        styles.sidebar,
        { [styles.isOpen]: isOpen },
        { [styles.isTransitioning]: isTransitioning },
      )}
      ref={sidebarRef}
    >
      <a href={rootPath}>
        <img
          alt="User Interview logo"
          className={styles.mobileLogo}
          src="/images/ui-logo-white.svg"
        />
      </a>

      <nav aria-label="primary navigation">
        <div className={styles.navLeft}>
          <div className={styles.logoWrapper}>
            <a href={rootPath}>
              <img
                alt="User Interview logo"
                className={styles.logo}
                src={isOpen ? '/images/ui-logo-white.svg' : '/images/ui-icon.svg'}
              />
            </a>
          </div>

          {isAccount && (
            <>
              <NavigationItem
                event={NAVIGATION_PROJECTS_CLICKED}
                href={routes.account_dashboard_path()}
                iconSrcBase="/images/nav_icon_projects"
                label="Projects"
                onHandleNavigating={onHandlePageLoading}
              />

              <NavigationItem
                event={NAVIGATION_PARTICIPANTS_CLICKED}
                href={routes.search_participants_path()}
                iconSrcBase="/images/nav_icon_participants"
                label="Participants"
                onHandleNavigating={onHandlePageLoading}
              />
            </>
          )}

          {isStaff && (
            <NavigationItem
              href="/staff"
              iconSrcBase="/images/nav_icon_participants"
              label="Staff"
            />
          )}
        </div>
        <div className={styles.navRight}>
          {isAccount && (
            <>
              {!authContext?.teamSubscription?.paidSubscription && (
                <NavigationItem
                  event={NAVIGATION_PLANS_CLICKED}
                  href="/teams/plans"
                  iconSrcBase="/images/nav_icon_explore_plans"
                  label="Explore plans"
                  onHandleNavigating={onHandlePageLoading}
                />
              )}
              <NavigationItem
                event={NAVIGATION_SUPPORT_CLICKED}
                href="/support"
                iconSrcBase="/images/nav_icon_support"
                label="Support"
                target="_blank"
              />
            </>
          )}

          <AccountNavigationItem />
        </div>
      </nav>
    </div>
  );
});

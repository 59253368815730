import React from 'react'
import { Turnstile } from '@marsidev/react-turnstile';
import { useEnvVars } from 'hooks/use_env_vars';

type CloudflareTurnstileProps = {
  onSuccess: () => void;
}

export function CloudflareTurnstile({
  onSuccess = () => {},
}: CloudflareTurnstileProps) {
  const { cloudflareTurnstileCaptchaSiteKey } = useEnvVars();

  return (
    <Turnstile
      siteKey={cloudflareTurnstileCaptchaSiteKey || ''}
      onSuccess={onSuccess}
    />
  )
}
